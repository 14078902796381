
// 装饰器是对现有的类或属性进行一个补充
import { Options, Vue } from "vue-class-component";
import LayoutHeader from './LayoutHeader.vue'
import Content from './Content.vue'
import Sidebar from './Sidebar.vue'
@Options({
  // 引入组件
  components: { LayoutHeader, Content, Sidebar },
})
export default class Layout extends Vue {
   mounted() {
  }
}
