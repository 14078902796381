
import { Options, Vue } from "vue-class-component";
import { Provide, Emit } from 'vue-property-decorator';
import { login } from '../api/user';
import { State, Getter, Mutation, Action } from 'vuex-class';
import { message } from 'ant-design-vue/es';
@Options({
  components: {
  },
})
export default class Login extends Vue {
  // 存储用户信息,给setUser设置any类型
  @Action('setUser') setUser: any
  @Provide() ruleForm: {
    // 形参
    username: String
    password: String
  } = {
    // 默认值
    username: 'admin',
    password: '123456',
  }

  @Provide() rules = {
    username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
    pwd: [{ required: true, message: '请输入密码', trigger: 'blur' }],
  }
  @Provide() isLogin: boolean = false

  handleSubmit(): void {
    let _this = this as any
   // as any定义refs类型
    login(_this.ruleForm)
      .then((res: any) => {
        // 存储TOKEN
        if(res.code ==200) {
          localStorage.setItem('tsToken', res.data.Authorization)
        // 存储token到vuex中
          this.setUser(res.data.Authorization)
        // 登录成功 跳转 
          this.$router.push('/management')
        } else {
          message.error(res.message);
        }
      })
      .catch((err: any) => {
        this.isLogin = false
      })
  }
}
