// GET /admin/ptjbxx/detail
import request from '@/untils/http';

/**
 * declareList类，代扣代缴申报列表
 * @constructor
 * @
 */
export function declareList(params?: any): any {
  return request({
   url: '/admin/sbxx/sumList',
   method: 'get',
   params,
  });
}

/**
 * declareListId类，代扣代缴明细列表
 * @constructor
 * @
 */
export function declareListId(params: any): any {
  return request({
   url: '/admin/sbxx/list',
   method: 'get',
   params
  });
}

/**
 * ownerDeclare类，车主申报
 * @constructor
 * @
 */
export function ownerDeclare(params: any): any {
  return request({
   url: '/admin/sbxx/push',
   method: 'post',
   params
  });
}


// 个税申报--/admin/sdsxx/list

export function getSdsxx(params?: any): any {
  return request({
   url: '/admin/sdsxx/sumList',
   method: 'get',
   params,
  });
}

// /admin/sdsxx/list

export function getSdsxxList(params?: any): any {
  return request({
   url: '/admin/sdsxx/list',
   method: 'get',
   params,
  });
}

// /admin/sdsxx

export function adminSdsxx(data: any): any {
  return request({
   url: '/admin/sdsxx',
   method: 'post',
   data,
  });
};