
import { Options, Vue } from 'vue-class-component';
import { Provide, Watch, Emit } from 'vue-property-decorator';
import { ownerList,fwfjbxxCheck } from '../../api/platform';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { message,Modal } from 'ant-design-vue/es';
import { h } from "vue";
type Key = ColumnProps['key'];
@Options({
  components: {
  },
})
export default class OwnerManagement extends Vue {
  @Provide() columns: any =[
   {
    title: '序号',
    customRender: ({text, record, index} :any)=> {
      return this.data.pageNum > 1 ? `${(this.data.pageNum -1)*(this.data.pageSize)+index+1}`:index+1
    },
  },
  {
    title: '姓名',
    dataIndex: 'fwfxm',
    slots: { customRender: 'fwfxm' },
  },
  {
    title: '验证状态',
    dataIndex: 'state',
    slots: { customRender: 'state' },
  },
  {
    title: '身份证号',
    dataIndex: 'fwfsfzjhm',
  },
  {
    title: '手机号',
    dataIndex: 'yddh',
  },
  {
    title: '车牌号',
    dataIndex: 'cycph',
  },
  {
    title: '注册时间',
    dataIndex: 'createTime',
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
  },
];
// 表单数据
formState: {
  fwfxm:string;
  cycph:string;
  yddh:string;
  fwfsfzjhm:string;
  pageNum:number;
  pageSize:number;
  state:number
} = {
  fwfxm:"",
  cycph:"",
  yddh:"",
  fwfsfzjhm:"",
  pageNum:1,
  pageSize:10,
  state:1
}
//列表数据
data: any = [];
visible:boolean = false;
selectedRowKeys:any=[]
pageSizeOptions:any = ['10', '20', '30', '40', '50']
//验证数据
verifyStatedOptions:any = [
  {name:"全部",id:0},
  {name:"待处理",id:1},
  {name:"已验证",id:2},
  {name:"已上传",id:3},
  {name:"上传中",id:4},
 ];
//选中
rowSelection:any = {
  onChange: (selectedRowKeys: Key[], selectedRows: []) => {
    this.selectedRowKeys=selectedRows
  },
  getCheckboxProps: (record: any) => ({
    disabled: record.name === 'Disabled User', // Column configuration not to be checked
    name: record.name,
  }),
 };
 
 failReason(text: string):void {
    Modal.warning({
      content: text,
      okText: '我知道了',
      class: 'aConfirm',
      centered: true,
    });
  }

  onValide(): void {
    if (!this.selectedRowKeys.length) {
      Modal.warning({
        content: h('p', '请先选中数据再验证！'),
        okText: '确定',
        class: 'aConfirm',
        centered: true,
      })
    }
    let num: any = []
    for (let i in this.selectedRowKeys) {
      num.push(this.selectedRowKeys[i].id)
    }
    fwfjbxxCheck({ids:num}).then((res: any)=> {
      if (res.code == 200) {
        message.info(res.message, 5)
         setTimeout(()=>{
           location.reload()
        },500)
      }
    })
  }

@Emit()
owerQuery(pageNumber?:number):void{
   const data: any= {
      pageNum:1,
      pageSize: 10,
      fwfxm: this.formState.fwfxm,
      cycph: this.formState.cycph,
      yddh: this.formState.yddh,
      state: this.formState.state,
      fwfsfzjhm:this.formState.fwfsfzjhm
   }
  ownerList(data).then((res:any)=>{
    this.data = res.data 
    // this.formState = res.data
  })
}

owerQuery1(pageNumber?:number):void{
   const data: any= {
      pageNum: this.formState.pageNum ,
      pageSize:this.formState.pageSize,
      fwfxm: this.formState.fwfxm,
      cycph: this.formState.cycph,
      yddh: this.formState.yddh,
      state: this.formState.state,
      fwfsfzjhm:this.formState.fwfsfzjhm
   }
  ownerList(data).then((res:any)=>{
    this.data = res.data 
    // this.formState = res.data
  })
}
//分页
 onChange1(pageNumber: number) :void {
    this.formState.pageNum = pageNumber
    // this.list()
    this.owerQuery1()
  }
  onShowSizeChange(current: number, pageSize: number):void {
     this.formState.pageNum = current
     this.formState.pageSize = pageSize
     this.owerQuery1()
  }
 shipperDetails(id: any): void{
   const data: any= {
      pageNum: this.formState.pageNum ,
      pageSize:this.formState.pageSize,
      fwfxm: this.formState.fwfxm,
      cycph: this.formState.cycph,
      yddh: this.formState.yddh,
      state: this.formState.state,
      fwfsfzjhm:this.formState.fwfsfzjhm
   }
   sessionStorage.setItem('praOwner',JSON.stringify(data))
   this.$router.push('/ownerDetails?query='+id)
 }
mounted() {
  // 车主列表接口
   let data: any= {
      pageNum: 1,
      pageSize: 10,
      fwfxm: this.formState.fwfxm,
      cycph: this.formState.cycph,
      yddh: this.formState.yddh,
      state: this.formState.state
   }
   if(sessionStorage.getItem('isOwnerDetail')){
      let aa: any=sessionStorage.getItem('praOwner')
      let bb: any=JSON.parse(aa)
      data=Object.assign({},data,bb)
      this.formState.pageNum=data.pageNum
      this.formState.pageSize=data.pageSize
      this.formState.fwfxm=data.fwfxm
      this.formState.cycph=data.cycph
      this.formState.yddh=data.yddh
      this.formState.state=data.state
    }
  ownerList(data).then((res:any)=>{
    this.data = res.data
    //  this.formState = res.data
  })
}
}
