/*
 * @Author: your name
 * @Date: 2021-07-06 08:39:58
 * @LastEditTime: 2021-07-06 17:33:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solomen_tax\src\main.ts
 */
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Antd from 'ant-design-vue/es';
import 'ant-design-vue/dist/antd.css';
import '@/assets/styles/reset.scss';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
// import globalData from './constants/global';
const app = createApp(App);
// app.config.globalProperties.$API = globalData;
app.use(store).use(router).use(Antd).mount('#app');
