import { ActionTree } from 'vuex';
import { asyncRouterMap } from '../router';

// login登录后会传token过来;在这里把token解析,得到具体的用户信息
import jwt_decode from 'jwt-decode';
const actions: ActionTree<any, any> = {
  // acitons为异步,
  async setUser({ state, commit }, user: any) {
    const decode: any = jwt_decode(user);
    commit('SET_USER', decode);
    // 从decode解析出用户key的角色返回应该显示的路由
    const { key } = decode;
    const accessedRouters = filterAsyncRouter(asyncRouterMap, key);
    commit('SET_ROUTERS', accessedRouters);
  },
};
/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param asyncRouterMap 所有路由表
 * @param roles 当前角色
 */
// tslint:disable-next-line:no-shadowed-variable
function filterAsyncRouter(asyncRouterMap: any[], roles: string) {
  const accessedRouters =  asyncRouterMap.filter((route) => {
    if (hasPermission(roles, route)) { // 判断当前角色是否有权限
      if (route.children && route.children.length) {// 对子菜单进行递归判断是否有权限
        route.children = filterAsyncRouter(route.children, roles);
      }
      return true;
    }
    return false;
  });
  return accessedRouters; // 新路由进行返回
}
/**
 * 判断是否有权限
 * @param roles 当前角色
 * @param route 当前路由对象
 */
function hasPermission(roles: string, route: any) {
  if (route.meta && route.meta.roles) {
    // 如果meta.roles是否包含角色的key值,如果包含那么就是有权限,否则无权限
    // includes
    return route.meta.roles.some((role: string) => role.indexOf(roles) >= 0);
  } else {
    // 默认不设置有权限
    return true;
  }
}
export default actions;
