/*
 * @Author: your name
 * @Date: 2021-07-06 08:39:58
 * @LastEditTime: 2021-07-10 10:08:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solomen_tax\src\untils\http.ts
 */
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import router from '@/router';
import { provide } from 'vue';
import { message } from 'ant-design-vue/es';
/**
 * http请求工具类
 *
 * 请求拦截器 负责将客户端标识token值存储并放置在头部提交给服务端
 *
 * 响应拦截器 负责全局处理业务请求的网络或者业务错误
 */

// 创建axios的实例
const baseURL = process.env.VUE_APP_BASEURL;
const service = axios.create({
  baseURL,
  timeout: 10000, // 超时时间
});
// 请求拦截
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (localStorage.tsToken) {
      config.headers.Authorization = 'Bearer ' + localStorage.tsToken;
    }
    return config;
  },
  (err: any) => {
    Promise.reject(err);
  },
);
// 响应拦截
service.interceptors.response.use(
  (response: AxiosResponse) => {
    let errMsg = '';
    if (response.data.code === 401) {
       errMsg = '登录状态失效，请重新登录';
       localStorage.removeItem('tsToken');
       router.push('/login');
       message.error(errMsg);
       return Promise.reject(errMsg);
    } else if (response.data.code === 500) {
      return  message.error(response.data.message);
    } else {
      return response.data;
    }
  },
  (err: any) => {
    let errMsg = '';
    if (err && err.response.status) {
      switch (err.response.status) {
        case 401:
          errMsg = '登录状态失效，请重新登录';
          localStorage.removeItem('tsToken');
          router.push('/login');
          break;
        case 403:
          errMsg = '拒绝访问';
          break;
        case 404:
          errMsg = '接口不存在';
          break;
        case 408:
          errMsg = '请求超时';
          break;
        case 500:
          errMsg = '服务器内部错误';
          break;
        case 501:
          errMsg = '服务未实现';
          break;
        case 502:
          errMsg = '网关错误';
          break;
        case 503:
          errMsg = '服务不可用';
          break;
        case 504:
          errMsg = '网关超时';
          break;
        case 505:
          errMsg = 'HTTP版本不受支持';
          break;
        default:
          errMsg = err.response.data.msg;
          break;
      }
    } else {
      errMsg = err;
    }
    message.error(errMsg);
    return Promise.reject(errMsg);
  },
);
export default service;
