
import { Options, Vue } from 'vue-class-component';
import { Provide } from 'vue-property-decorator';
import { message } from 'ant-design-vue/es';
import { commonGetInfo,Greeter,theManagementListFbfjbxx } from '../../api/platform';
interface FormState {
  nsrmc: string;
  nsrsbh: string;
  hy: string;
  zgswskfj: string;
  nsrzt: string;
  zcdz: string;
  zcdzxzqh: string;
  zgswjg: string;
  fddbrxm: string;
  scjydzxzqh: string;
  jdxz: string;
  scjydz: string;
}
@Options({
  components: {
  },
})
export default class OrdInfo extends Vue {
   @Provide() columns: any
   rules: any = {
      nsrmc: [
        { required: true, message: '请输入纳税人名称', trigger: 'blur' },
      ],
      nsrsbh: [
        { required: true, message: '请输入纳税人识别号', trigger: 'blur' },
      ],
      hy: [
        { required: true, message: '请选择行业', trigger: 'blur' },
      ],
      fddbr: [
         { required: true, message: '请输入法定代表人', trigger: 'blur' },
      ],
      qyyddh: [
         { required: true, message: '请输入企业手机号', trigger: 'blur' },
      ],
      zgswjg: [
        { required: true, message: '请输入主管税务机关', trigger: 'blur' },
      ],
      nsrlx: [
        { required: true, message: '请选择企业类型', trigger: 'blur' },
      ],
      zczb: [
        { required: true, message: '请输入注册资本', trigger: 'blur' },
      ],
      slrq: [
        { required: true, message: '请输入成立日期', trigger: 'blur' },
      ],
      gsdz:[
        { required: true, message: '请输入公司地址', trigger: 'blur' },
      ],
      xxdz:[
        { required: true, message: '请输入详细地址', trigger: 'blur' },
      ],
      ssdq:[
        { required: true, message: '请输入所属地区', trigger: 'blur' },
      ],
      jyfw: [
        { required: true, message: '请输入经营范围', trigger: 'blur' },
      ],
      ptzcsj: [
        { required: true, message: '请输入注册时间', trigger: 'blur' },
      ],
   }  
   formState: {
      nsrmc: string;
      nsrsbh: string;
      hy: string;
      zgswskfj: string;
      nsrzt: string;
      zcdz: string;
      zcdzxzqh: string;
      zgswjg: string;
      fddbrxm: string;
      scjydzxzqh: string;
      jdxz: string;
      scjydz: string;
      bsrsfzjzl: string;
      nsrlx: string;
      gjdq: string;
      fbfxm:string;
      yyzzfjPic:string;
   } = {
      nsrmc:"",
      nsrsbh: "",
      hy: "",
      zgswskfj: "",
      nsrzt: "正常",
      zcdz: "",
      zcdzxzqh: "",
      zgswjg: "",
      fddbrxm: "",
      scjydzxzqh:"",
      jdxz: "",
      scjydz: "",
      bsrsfzjzl: "",
      nsrlx: "小规模",
      gjdq: "中华人民共和国",
      fbfxm:"",
      yyzzfjPic:""
  }
  imageUrl: string = "";
  //验证数据
  loading: boolean = false;
  verifyStatedOptions:object = [
    {name:"一般人",id: "0"},
    {name:"小规模",id: "1"},
  ];
  taxOffice:object = [];
  eachDistrict:object = [];
  url: string = process.env.VUE_APP_BASEURL;
  location:any = localStorage.tsToken;
  previewVisible:boolean = false;
  fileList:any = [];

  //base64解析
  getBase64(img: Blob, callback: (base64Url: string) => void) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result as string));
      reader.readAsDataURL(img);
   }

  // 图片上传
    handleChange = (info: any) => {
      
      if (info.file.status === 'uploading') {
         return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
         this.formState.yyzzfjPic = info.file.response.data.url;
       
      }
      if (info.file.status === 'error') {
        message.error('上传失败');
      }
  }
  // 上传图片
  handlePreview():void {
    this.previewVisible = true
  }
  // 取消
  handleCancel():void {
    this.previewVisible = false
  }

  //图片上传大小认证
    beforeUpload = (file: any) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('只能上传jpg和png格式图片');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('图片大小不能大于2MB!');
      }
      return isJpgOrPng && isLt2M;
   };

  // 获取公告的方法
  handleSearchCertificate(val:any): void{
    let handCommon= new Greeter(val,3);
    handCommon.greet().then((res:any)=>{
      this.taxOffice = res.data.list
    })
  }
  // 获取地区
  handleSearchEachDistrict(val:any): void{
    let handCommon= new Greeter(val,2);
    handCommon.greet().then((res:any)=>{
      this.eachDistrict = res.data.list
    })
  }
  // 保存
  save():void {
     this.formState.gjdq = "156"
     theManagementListFbfjbxx(this.formState).then((res: any) =>{
       if (res.code ==200) {
         message.success(res.message);
       }
    })
  }
  mounted() {
     // console.log(this.$router.currentRoute.value.query)
    const data:any = {
      appId:this.$router.currentRoute.value.query.app_id,
      signKey:this.$router.currentRoute.value.query.sign_key,
      time:this.$router.currentRoute.value.query.time,
      id:this.$router.currentRoute.value.query.fbfuuid,
      infoType:2
    }
    commonGetInfo(data).then((res: any) =>{
      this.formState = res.data
      this.fileList.push({
         uid: '-1',
         name: 'image.png',
         status: 'done',
         url:res.data.yyzzfjPic
      })
      this.formState.gjdq ="中华人民共和国"
    })
  }
}
