
import { Options, Vue } from "vue-class-component";
import { Provide, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
@Options({
  components: {},
})
export default class Sidebar extends Vue {
  @Getter('routers') getRouters: any //获取vuex中计算属性的路由,getRouters为当前自定义
  created() {}
  mounted() {}
}
