
// 装饰器是对现有的类或属性进行一个补充
import { Options, Vue } from "vue-class-component";
import { State, Getter, Mutation, Action } from 'vuex-class';

@Options({
  // 引入组件
  components: {},
})
// home组件继承vue
export default class Home extends Vue {
  // 获取state中的user,对getter中的 getuser进行获取
  @Getter('user') getUser: any
  $router: any
  visible:boolean=false
  logOut():void {
    this.visible = true;
  }
  handleOk():void {
     this.visible = false;
     localStorage.removeItem('tsToken');
     this.$router.push('/login');
  }
  // 点设置功能
  userCommand(command: string): void {
    if (command == 'logout') {
      localStorage.removeItem('tsToken')
      this.$router.replace('/login') //跳转到指定URL，替换history栈中最后一个记录，点击后退会返回至上上一个页面
    }
    if (command == 'usercenter') this.$router.push('/user')
  }
  created() {}
}
