
import { declareList, ownerDeclare } from '../../api/declare';
import { Options, Vue } from 'vue-class-component';
import { Provide, Watch, Emit } from 'vue-property-decorator';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { Modal } from 'ant-design-vue';
import { h } from 'vue';
type Key = ColumnProps['key'];
interface declare {
  pageNum: number;
  pageSize: number;
  skssqq:string;
  skssqz:string;
  state: number,
}
@Options({
  components: {
  },
})

export default class DeclareManage extends Vue {
  @Provide() columns: any =[
  {
    title: '税款所属期起',
    dataIndex: 'skssqq',
  },
  {
    title: '税款所属期止',
    dataIndex: 'skssqz',
  },
  {
    title: '应交税总额',
    dataIndex: 'ydzseze',
  },
  {
    title: '车主数',
    dataIndex: 'czs',
  },
  {
    title: '订单数',
    dataIndex: 'dds',
  },
  {
    title: '申报状态',
    dataIndex: 'state',
    slots: { customRender: 'state' },
  },
  {
    title: '申报时间',
    dataIndex: 'declareTime',
  },
  {
    title: '操作',
    dataIndex: "action",
    slots: { customRender: 'action' }, align: "center"
  }
];
//列表数据
data: any = [];
// 表单数据
form: {
  skssqq:string;
  skssqz:string,
  state: number;
} = {
  skssqq: '',
  skssqz: '',
  state: 0,
}
formState:any = []
// 弹出框
visible: boolean = false
// 车主id
sbsids: string = ''
@Emit()
/**
 * onChange类，日期
 * @constructor
 * @return {*}
 */
onChange(date: string, dateString: string): void {
  this.form.skssqq = dateString[0]
  this.form.skssqz = dateString[1]
}

//分页
 onChange1(pageNumber: number) :void {
    this.formState.pageNum = pageNumber
    this.declareQuery()
  }
 /**
 * declareQuery类，代扣代缴申报查询
 * @constructor
 * @return {*}
 */
 declareQuery(): void{
   const data: declare= {
      pageNum: this.formState.pageNum,
      pageSize: 10,
      ...this.form
   }
  this.declareData(data)
 }
 /**
 * declareData类，代扣代缴申报列表接口
 * declareList接口参数限制
 * @constructor
 * @return {*}
 */
 declareData(data?: object): void {
   declareList(data).then((res: any) => {
     this.data = res.data.list
     this.formState = res.data;
   })
  }
 /**
 * showModal类，显示弹窗
 * @constructor
 * @return {*}
 */
  showModal(record: any): void {
    this.visible = true
    this.sbsids = record.sbsids
  }
 /**
 * handleOk类，弹出框确定按钮
 * @constructor
 * @return {*}
 */
  handleOk(): void {
    this.visible = false;
    ownerDeclare({ sbsids : this.sbsids}).then((res: any) => {
      if (res.code === 200 ) {
        const pushData = res.data
        if (pushData.status == 1) {
          Modal.info({
            icon: h('img', {
              src: require('@/assets/images/icon/success.png')
            }),
            content:  h('p', '申报成功'),
            okText: '我知道了',
            class: 'declareConfirm',
            width: '384px',
            centered: true,
          })
        }
        if (pushData.status == 0) {
          const sum = pushData.failNum + pushData.successNum
          Modal.info({
            icon: h('img', {
              src: require('@/assets/images/icon/failed.png')
            }),
            content: h('div', {}, [
              h('p', '申报失败'),
              h('p', '请处理后再次申报'),
              h('p', `失败原因：本次推送总计${sum}个车主数据，其中${pushData.failNum}个车主推送失败，点击明细查看详情，待处理后，继续执行申报`),
            ]),
            okText: '我知道了',
            class: 'declareConfirm',
            width: '384px',
            centered: true,
          })
        }
        this.declareData()
      }
    })
  }
 /**
 * failReason类，失败原因弹窗
 * @constructor
 * @return {*}
 */
  failReason(text: string) {
    Modal.warning({
      content: text,
      okText: '我知道了',
      class: 'declareConfirm',
      centered: true,
    });
  }
  mounted(){
    this.declareData()
  }
}
