/*
 * @Author: your name
 * @Date: 2021-07-09 16:28:36
 * @LastEditTime: 2021-07-15 17:46:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solomen_tax\src\api\order.ts
 */
import request from '@/untils/http';
import qs from 'qs';

export function list(params?: any): any {
  return request({
   url: '/admin/ddxx',
   method: 'get',
   params,
   paramsSerializer: params => {
    return qs.stringify(params, { indices: false });
  },
  });
}

export function detail(id: string | string []): any {
  return request({
    url: `/admin/ddxx/${id}`,
    method: 'get',
  })
}

export function update(params: any): any {
  return request({
    url: `/admin/ddxx`,
    method: 'put',
    data: { ...params }
  })
}

export function check(params: number[]): any {
  return request({
    url: `/admin/ddxx/check`,
    method: 'POST',
    data: { ids: [...params] }
  })
}
export function upload(params: number[]): any {
  return request({
    url: `/admin/ddxx/push`,
    method: 'POST',
    data: { ids: [...params] }
  })
}
export function exportfile(params: any): any {
  return request({
   url: '/admin/ddxx/exportExcel',
   method: 'POST',
   responseType: 'blob',
   data : { ...params }
  });
}
