import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory} from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Layout from '../views/Loyout/index.vue';
import TheManagement from '../views/theManagement/TheManagement.vue';
import Owner from '../views/OwnerManagement/Owner.vue';
import Invoice from '../views/Invoice/Invoice.vue';
import registration from '../views/registration/registration.vue';
import Declare from '../views/DeclareManage/index.vue';
import DeclareDetail from '../views/DeclareManage/DeclareDetail.vue';
import jwt_decode from 'jwt-decode';
import PublishInfo from '../views/theManagement/OrdInfo1.vue';
import ServiceInfo from '../views/OwnerManagement/OwnerDetails_1.vue';
import OrderInfo from '../views/Order/OrderDetail_1.vue';
export const asyncRouterMap = [
  {
    // 重定向
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    hidden: false,
    component: Login,
  },
  // /gxpt/data/publish_info 发布方
  {
    path: '/publish_info',
    name: 'publish_info',
    hidden: false,
    component: PublishInfo,
  },
  // 服务方
  {
    path: '/service_info',
    name: 'service_info',
    hidden: false,
    component: ServiceInfo,
  },
   // 订单
   {
    path: '/order_info',
    name: 'order_info',
    hidden: false,
    component: OrderInfo,
  },
  {
    path: '/management',
    name: 'management',
    component: Layout,
    hidden: true,
    redirect: '/management',
    meta: {
      title: '货主管理',
      icon: 'el-icon-s-data',
    },
    children: [
      {
        path: '/management',
        name: 'management',
        meta: {
          title: '货主管理',
          icon: 'el-icon-s-home',
        },
        component: TheManagement,
      },
    ],
  },
  
  {
    path: '/owne',
    component: Layout,
    hidden: true,
    redirect: '/owner',
    meta: {
      title: '车主管理',
      icon: 'el-icon-s-data',
    },
    children: [
      {
        path: '/owner',
        name: 'owner',
        meta: {
          title: '车主管理',
          icon: 'el-icon-s-home',
        },
        component: Owner,
      },
    ],
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    redirect: '',
    meta: {
      title: '订单管理',
      icon: 'el-icon-s-data',
    },
    children: [
      {
        path: '/pushorder',
        name: 'pushorder',
        hidden: true,
        meta: {
          title: '推送订单',
          icon: 'el-icon-s-home',
        },
        component: () => import('@/views/Order/PushOrder.vue'),
      },
      {
        path: '/errororder',
        name: 'orderDetail',
        hidden: true,
        meta: {
          title: '异常订单',
          icon: 'el-icon-s-home',
        },
        component: () => import('@/views/Order/ErrorOrder.vue'),
      },
    ],
  },
  {
    component: Layout,
    path: '',
    name: 'orderdetail',
    hidden: false,
    meta: {
      title: '订单管理',
      icon: 'el-icon-s-home',
    },
    children: [
      {
        path: '/order/:id',
        name: 'orderdetail',
        hidden: false,
        meta: {
          title: '订单详情',
          icon: 'el-icon-s-home',
        },
        component: () => import('@/views/Order/OrderDetail.vue'),
      },
    ],
  },
  {
    path: '/declareManage',
    component: Layout,
    hidden: true,
    redirect: '/declareManage',
    meta: {
      title: '商税申报',
      icon: 'el-icon-s-data',
    },
    children: [
      {
        path: '/declareManage',
        name: 'declareManage',
        meta: {
          title: '商税申报',
          icon: 'el-icon-s-home',
        },
        component: Declare,
      },
    ],
  },
  {
    path: '/declareManage',
    component: Layout,
    hidden: false,
    redirect: '/declareManage',
    meta: {
      title: '商税申报',
      icon: 'el-icon-s-data',
    },
    children: [
      {
        path: '/declareManage/:id',
        name: 'declareDetail',
        meta: {
          title: '商税申报明细',
          icon: 'el-icon-s-home',
        },
        component: DeclareDetail,
      },
    ],
  },
  {
    path: '/taxDeclaration',
    component: Layout,
    hidden: true,
    redirect: '/taxDeclaration',
    meta: {
      title: '个税申报',
      icon: 'el-icon-s-data',
    },
    children: [
      {
        path: '/taxDeclaration',
        name: 'taxDeclaration',
        meta: {
          title: '个税申报',
          icon: 'el-icon-s-home',
        },
        component: () => import('@/views/TaxDeclaration/index.vue'),
      },
    ],
  },

  {
    path: '/taxDetail',
    component: Layout,
    hidden: false,
    redirect: '/taxDetail',
    meta: {
      title: '个税明细',
      icon: 'el-icon-s-data',
    },
    children: [
      {
        path: '/taxDetail',
        name: 'taxDetail',
        meta: {
          title: '个税明细',
          icon: 'el-icon-s-home',
        },
        component: () => import('@/views/TaxDeclaration/DeclareDetail.vue'),
      },
    ],
  },


  {
    path: '/platform',
    component: Layout,
    hidden: true,
    redirect: '/platform',
    meta: {
      title: '平台管理',
      icon: 'el-icon-s-data',
    },
    children: [
      {
        path: '/platform',
        name: 'platform',
        meta: {
          title: '平台管理',
          icon: 'el-icon-s-home',
        },
        component: () => import('@/views/Platform/Platform.vue'),
      },
    ],
  },

  {
    path: '/orderInfo',
    component: Layout,
    hidden: false,
    redirect: '/orderInfo',
    meta: {
      title: '平台管理',
      icon: 'el-icon-s-data',
    },
    children: [
      {
        path: '/orderInfo',
        name: 'orderInfo1',
        meta: {
          title: '货主详情',
          icon: 'el-icon-s-home',
        },
        component: () => import('@/views/theManagement/OrdInfo.vue'),
      },
    ],
  },
  {
    path: '/ownerDetails',
    component: Layout,
    hidden: false,
    redirect: '/ownerDetails',
    meta: {
      title: '车主管理',
      icon: 'el-icon-s-data',
    },
    children: [
      {
        path: '/ownerDetails',
        name: 'orderInfo',
        meta: {
          title: '车主详情',
          icon: 'el-icon-s-home',
        },
        component: () => import('@/views/OwnerManagement/OwnerDetails.vue'),
      },
    ],
  },
  {
    path: '/Invoice',
    component: Layout,
    hidden: true,
    redirect: '/Invoice',
    meta: {
      title: '销项发票',
      icon: 'el-icon-s-data',
    },
    children: [
      {
        path: '/Invoice',
        name: 'Invoice',
        meta: {
          title: '销项发票',
          icon: 'el-icon-s-home',
        },
        component: Invoice,
      },
    ],
  },
  {
    path: '/registration',
    component: Layout,
    hidden: true,
    redirect: '/registration',
    meta: {
      title: '临时纳税人',
      icon: 'el-icon-s-data',
    },
    children: [
      {
        path: '/registration',
        name: 'registration',
        meta: {
          title: '临时纳税人',
          icon: 'el-icon-s-home',
        },
        component: registration,
      },
    ],
  },
  {
    path: '/404',
    name: '404',
    hidden: false,
    component: () => import('@/views/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: asyncRouterMap,
});
router.beforeEach((to: any, from: any, next: any) => {
  const isLogin = localStorage.tsToken ? true : false;
  if (to.path === '/login' || to.path === '/password') {
    next();
  } else {
    // if (isLogin) {
    //   next();
    // } else {
    //   next('/login');
    // }
    //订单管理
    if(to.path !== '/pushorder' && !from.path.includes('/order')){
      sessionStorage.removeItem('isDetail')
      // sessionStorage.removeItem('pra')
    }
    //司机管理
    if(to.path !== '/management' && !from.path.includes('/orderInfo')){
      sessionStorage.removeItem('isManageDetail')
    }
    //车主管理
    if(to.path !== '/owner' && !from.path.includes('/ownerDetails')){
      sessionStorage.removeItem('isOwnerDetail')
    }
    next();
  }
});
/**
 * 判断是否有权限
 * @param roles 当前角色
 * @param route 当前路由对象
 */
function hasPermission(roles: string, route: any) {
  if (route.meta && route.meta.roles) {
    // 如果meta.roles是否包含角色的key值,如果包含那么就是有权限,否则无权限
    return route.meta.roles.some((role: string) => role.indexOf(roles) >= 0);
  } else {
    // 默认不设置有权限
    return true;
  }
}

export default router;
