/*
 * @Author: your name
 * @Date: 2021-07-16 10:40:23
 * @LastEditTime: 2021-07-16 10:58:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solomen_tax\src\untils\until.ts
 */
/**
 * @description: 深拷贝
 * @param {any} obj
 * @return {*}
 */
export function deepCopy(obj: any) {
  if ( typeof obj !== 'object' ) {
    return obj;
  }
  return JSON.parse(JSON.stringify(obj));
}

/*
* fn [function] 需要防抖的函数
* delay [number] 毫秒，防抖期限值
*/
export function debounce(fn: any, delay: number) {
    let timer: any = null; // 借助闭包
    return function(): void {
        if (timer) {
            clearTimeout(timer); // 进入该分支语句，当前正在一个计时过程中，并且又触发了相同事件。所以要取消当前的计时，重新开始计时
            timer = setTimeout(fn, delay);
        } else {
            timer = setTimeout(fn, delay); // 进入该分支当前并没有在计时，那么就开始一个计时
        }
    };
}

/*
* fn [function] 需要节流的函数
* delay [number] 毫秒，节流期限值
*/
export function throttle(fn: any, delay: number) {
  let valid: boolean = true;
  return function() {
     if (!valid) {
         // 休息时间
         return false;
     }
     // 工作时间，执行函数并且在间隔期内把状态位设为无效
     valid = false;
     setTimeout(() => {
          fn();
          valid = true;
      }, delay);
  };
}
