
import { Options, Vue } from 'vue-class-component';
import { Provide, Watch, Emit } from 'vue-property-decorator';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { message,Modal } from 'ant-design-vue/es';
import { h } from "vue";
import { theManagementList,platformCheck } from '../../api/platform';
type Key = ColumnProps['key'];
interface shipper {
  pageNum: number;
  pageSize: number;
  nsrmc: string;
  nsrsbh: string;
  qyyddh: string;
  state: number;
}
@Options({
  components: {
  },
})
export default class TheManagement extends Vue {
  @Provide() columns: any =[
  {
    title: '序号',
    customRender: ({text, record, index} :any)=> {
      return this.data.pageNum > 1 ? `${(this.data.pageNum -1)*(this.data.pageSize)+index+1}`:index+1
    },
  },
  {
    title: '企业名称',
    dataIndex: 'nsrmc',
    slots: { customRender: 'nsrmc' },
  },
  {
    title: '验证状态',
    dataIndex: 'state',
    slots: { customRender: 'state' },
  },
  {
    title: '法定代表人',
    dataIndex: 'fddbr',
  },
  {
    title: '企业手机号码',
    dataIndex: 'qyyddh',
  },
  {
    title: '注册时间',
    dataIndex: 'ptzcsj',
  },
  {
    title: '企业纳税人识别号',
    dataIndex: 'nsrsbh',
  },
  {
    title: '注册资本（万元）',
    dataIndex: 'zczb',
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
  },
];
// 列表数据
data: any = [];
selectedRowKeys:any=[]

// 选中
rowSelection:any = {
  onChange: (selectedRowKeys: Key[], selectedRows: []) => {
    this.selectedRowKeys = selectedRows
  },
  getCheckboxProps: (record: any) => ({
    disabled: record.name === 'Disabled User', // Column configuration not to be checked
    name: record.name,
  }),
 };
// 表单数据
formState: {
  nsrmc:string;
  verifyState:string;
  nsrsbh:string;
  qyyddh:string;
  state:number;
  pageNum:number;
  pageSize:number;
} = {
  nsrmc:"",
  verifyState:"",
  nsrsbh:"",
  qyyddh:"",
  state:1,
  pageNum:1,
  pageSize:1
}
dataNumber:any =[];
//分页
 onChange1(pageNumber: number) :void {
    this.formState.pageNum = pageNumber
    // this.list()
    this.shipperQuery1()
  }
//表单认证
rules: any = {
  nsrmc: [],
}
total: number = 0
//验证数据
verifyStatedOptions:any = [
  {name:"全部",id:0},
  {name:"待处理",id:1},
  {name:"已验证",id:2},
  {name:"已上传",id:3},
  {name:"上传中",id:4},
 ];
 @Emit()
/**
 * shipperDetails类，货主详情
 * @constructor
 * @return {*}
 */
 shipperDetails(id: any): void{
   const data: shipper= {
      pageNum: 1,
      pageSize: 10,
      nsrmc: this.formState.nsrmc,
      nsrsbh: this.formState.nsrsbh,
      qyyddh: this.formState.qyyddh,
      state: this.formState.state
   }
   sessionStorage.setItem('praManage',JSON.stringify(data))
   this.$router.push('/orderInfo?query='+id)
 }
 /**
 * shipperQuery类，货主查询
 * shipper接口参数限制
 * @constructor
 * @return {*}
 */
 shipperQuery(): void{
   const data: shipper= {
      pageNum: 1,
      pageSize: 10,
      nsrmc: this.formState.nsrmc,
      nsrsbh: this.formState.nsrsbh,
      qyyddh: this.formState.qyyddh,
      state: this.formState.state
   }
  // 货主查询接口
  theManagementList(data).then((res: any) =>{
    this.data = res.data
  })
 }

  shipperQuery1(): void{
   const data: shipper= {
      pageNum: this.formState.pageNum,
      pageSize: 10,
      nsrmc: this.formState.nsrmc,
      nsrsbh: this.formState.nsrsbh,
      qyyddh: this.formState.qyyddh,
      state: this.formState.state
   }
  // 货主查询接口
  theManagementList(data).then((res: any) =>{
    this.data = res.data.list
  })
 }

 

  failReason(text: string):void {
    Modal.warning({
      content: text,
      okText: '确定',
      class: 'aConfirm',
      centered: true,
    });
  }

  onValide(): void {
    if (!this.selectedRowKeys.length) {
      Modal.warning({
        content: h('p', '请先选中数据再验证！'),
        okText: '确定',
        class: 'aConfirm',
        centered: true,
      })
    }
    let num: any = []
    for (let i in this.selectedRowKeys) {
      num.push(this.selectedRowKeys[i].id)
    }
    platformCheck({ids:num}).then((res: any)=> {
      if (res.code == 200) {
        message.info(res.message, 5)
        setTimeout(()=>{
           location. reload()
        },500)
      }
    })
  }
 mounted(){
   let data: shipper= {
      pageNum: 1,
      pageSize: 10,
      nsrmc: this.formState.nsrmc,
      nsrsbh: this.formState.nsrsbh,
      qyyddh: this.formState.qyyddh,
      state: this.formState.state
   }
   if(sessionStorage.getItem('isManageDetail')){
      let aa: any=sessionStorage.getItem('praManage')
      let bb: any=JSON.parse(aa)
      data=Object.assign({},data,bb)
      this.formState.pageNum=data.pageNum
      this.formState.pageSize=data.pageSize
      this.formState.nsrmc=data.nsrmc
      this.formState.nsrsbh=data.nsrsbh
      this.formState.qyyddh=data.qyyddh
      this.formState.state=data.state
    }
    // 货主列表接口
    theManagementList(data).then((res: any) =>{
      this.data = res.data
    })
 }
}
