
import { Options, Vue } from 'vue-class-component';
import { message } from 'ant-design-vue/es';
// import { cloneDeep } from '@types/lodash-es';
import { Emit, Provide, Watch } from 'vue-property-decorator';
import { platformManagement, commonController, fwfjbxxSave, commonGetInfo} from '../../api/platform';
interface FormState {
  nsrmc: string;
  nsrsbh: string;
  hy: string;
  zgswskfj: string;
  nsrzt: string;
  zcdz: string;
  zcdzxzqh: string;
  zgswjg: string;
  fddbrxm: string;
  scjydzxzqh: string;
  jdxz: string;
  scjydz: string;
}
interface ownerList {
  pageNum: number;
  pageSize: number;
  name: string;
  type: number;
}
/**
 * Greeter类，公告查询
 * @constructor
 * @return {*}
 */
class Greeter {
    val: string;
    index: number;
    constructor(message: string,type: number) {
      this.val = message;
      this.index = type
    }
    greet() {
       const data:any = {
         pageNum: 1,
         pageSize: 100,
         name: this.val,
         type: this.index
       }
      return commonController(data)
    }
}
@Options({
  components: {
  },
})
export default class OwnerDatails extends Vue {
 @Provide() columns: any =[
  {
    title: '开户人姓名',
    dataIndex: 'khrxm',
  },
  {
    title: '开户人身份证号',
    dataIndex: 'khrsfzjhm',
    slots: { customRender: 'khrsfzjhm' },
  },
  {
    title: '开户银行',
    dataIndex: 'khyh',
    slots: { customRender: 'khyh' },
  },
  {
    title: '银行卡号',
    dataIndex: 'skzh',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    slots: { customRender: 'operation' },
  },
];
   rules: any = {
      nsrmc: [
        { required: true, message: '请输入纳税人名称', trigger: 'blur' },
      ],
      nsrsbh: [
        { required: true, message: '请输入纳税人识别号', trigger: 'blur' },
      ],
      fwfsfzjhm: [
         { required: true, message: '请输入身份证号码', trigger: 'blur' },
         { pattern: /^[0-9]{17}[0-9|x|X]{1}$/, message: '请输入合法的身份证号',trigger: 'blur' },
      ],
      yddh: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
         { pattern: /^1[3|4|5|7|8]\d{9}$/, message: '请输入合法的手机',trigger: 'blur' },
      ],
      jszjhm: [
         { required: true, message: '请输入驾驶证件号码', trigger: 'blur' },
      ],
      ptzcsj: [
         { required: true, message: '请输入服务方注册时间', trigger: 'blur' },
      ],
      cycph: [
         { required: true, message: '请输入承运车牌号', trigger: 'blur' },
      ],
      xszjhm: [
         { required: true, message: '请输入行驶证件号码', trigger: 'blur' },
      ],
      cycc: [
        { required: true, message: '请输入承运车长', trigger: 'blur' },
      ],
      cyzz: [
        { required: true, message: '请输入承运载重（吨）', trigger: 'blur' },
      ],
      cypp: [
         { required: true, message: '请输入承运车辆品牌', trigger: 'blur' },
      ],
      clsyr:[
        { required: true, message: '请输入车辆所有人', trigger: 'blur' },
      ],
      cycsjsyr:[
         { required: true, message: '请输入实际所有人', trigger: 'blur' },
      ],
      cycsyrlxfs:[
         { required: true, message: '请输入所有人联系方式', trigger: 'blur' },
      ],
      cyczzl:[
        { required: true, message: '请输入总质量（吨）', trigger: 'blur' },
      ],
      cyczbzl:[
        { required: true, message: '请输入整备质量（吨）', trigger: 'blur' },
      ],
      ssdq: [
         { required: true, message: '请输入服务方所属地区', trigger: 'blur' },
      ],
      hy: [
        { required: true, message: '请选择行业', trigger: 'blur' },
      ],
      nsrzt: [
         { required: true, message: '请输入纳税人状态', trigger: 'blur' },
      ],
      ccnf: [
         { required: true, message: '请输入出厂年份', trigger: 'blur' },
      ],
      cyczcsj:[{
        required: true, message: '请输入出厂年份', trigger: 'blur'
      }],
      cycfdjhm:[{
        required: true, message: '请输入发动机号码', trigger: 'blur'
      }],
      jyrq:[{
        required: true, message: '请输入检查日期', trigger: 'blur'
      }],
      cycsbh:[{
        required: true, message: '请输入车辆识别代码', trigger: 'blur'
      }],
      cycsyxz:[{
        required: true, message: '请输入使用性质', trigger: 'blur'
      }],
      fwfsfzjlx:[{
         required: true, message: '请输入使用性质', trigger: 'blur'
      }]
   }  
  formState:any = {
    vehicle:1,
    jszfjPic: [],
    smrzfjPic: [],
    clxxModels:[
      { cycc:"",cycx:"",
        xszjhm:"",cycph:"",cyzz:"",
        cypp:"",cycsyrlxfs:"",
        cycsjsyr:"",clsyr:"",
        cyczzl:"",cyczcsj:"",cycfdjhm:"",
        jyrq:"",cycsbh:"",cycsyxz:"",
        cycxszfjPic:[],
        cyczpfjPic:[],
      }
    ]
  }
  //验证数据
  verifyStatedOptions:any = [];
  //证件类型
  personCertificate:any = [];
  //主管税务所
  taxOffice:any = [];
  // 选中值
  vehicle:number = 1;
  // 选中
  editableData:any =[]
  // 图片
  frontPhoto:any = [];
  frontPhoto1:any = [];
  edit = (key: string) => {
    //  this.editableData[key] = cloneDeep(dataSource.value.filter(item => key === item.key)[0]);
    };
  url: string = process.env.VUE_APP_BASEURL;
  location:any = localStorage.tsToken;
  previewVisible:boolean = false;
  smrzfjPicBoole:boolean = false;
  smrzfjPicBoole1:boolean = false;
  fileList:any = [];
  urlPic:any = [];
  @Emit()
/**
 * handleSearch类，行业查询
 * shipper接口参数限制
 * @constructor
 * @return {*}
 */
  handleSearch(val:any): void{
    const data:ownerList={
       pageNum: 1,
       pageSize: 100,
       name: val,
       type: 4
    }
    commonController(data).then((res: any) => {
       this.verifyStatedOptions = res.data.data.list
    })
  }
 /**
 * Greeter类，查询
 * handleSearchCertificate方法，主管税务局接口查询
 * @constructor
 * @return {*}
 */
  // handleSearchCertificate(val:any): void{
  //   let handCommon= new Greeter(val,4);
  //   handCommon.greet().then((res:any)=>{
  //     this.taxOffice = res.data.data.list
  //   })
  // }

  // 更改文件
  // 图片上传
  handleChange = (info: any) => {  
      this.handleChange(info,)
  }
  handeUpde =(info:any,index:number) => {
    if (info.file.status === 'uploading') {
         return;
      }
      if (info.file.status === 'done') {
         this.formState.yyzzfjPic = info.file.response.data.url;
      }
      if (info.file.status === 'error') {
        message.error('上传失败');
      }
  }

   // 上传图片
  handlePreview():void {
    this.previewVisible = true
  }

  // 取消
  handleCancel():void {
    this.previewVisible = false
  }

  // 查看图片
  enterprisePhone(data:any){

  }
  
/**
 * save方法，平台信息保存
 * @constructor
 * @return {*}
 */
  save():void {
    // this.formState.clxxModels.forEach((data:any,key:any) => {
    //   delete data.url;
    //   delete data.urlBoolen;
    //   delete data.urlBank;
    //   delete data.urlBankBoolen;
    //   delete data.urlCarrier;
    //   delete data.urlCarrierBoolen;
    //   delete data.urlPayment;
    //   delete data.uurlPaymentBoolen;
    //   delete data.urlRental;
    //   delete data.urlRentalBoolen;
    // })
    fwfjbxxSave(this.formState).then((res:any)=>{
      if(res.code==200) {
         message.success(res.message);
      }
    })
    this.overList()
  }

  // 
  overList():void {
   const data:any = {
      appId:this.$router.currentRoute.value.query.app_id,
      signKey:this.$router.currentRoute.value.query.sign_key,
      time:this.$router.currentRoute.value.query.time,
      id:this.$router.currentRoute.value.query.fwfuuid,
      infoType:3
    }
    commonGetInfo(data).then((res: any) =>{
      this.formState = res.data;
       if(this.formState.smrzfjPic==null) {
        this.formState.smrzfjPic =["",""]
      }
    })
   
  }
  mounted() {
    this.overList()
  }
    // 获取详情
   
}
