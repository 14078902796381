import request from '@/untils/http';
import { getCurrentInstance } from 'vue';

// 分环境打包
// let baseUrl = '';
// if (process.env.NODE_ENV === 'development') {
//   baseUrl = '/base';
// } else if (process.env.NODE_ENV === 'production') {
//   baseUrl = process.env.VUE_APP_BASE_SERVER;
// } else {
//   baseUrl = '';
// }
// 登录
/**
 * login类，登录接口
 * @constructor
 * @
 */
export function login(data: any): any {
  return request({
    url: '/login',
    method: 'post',
    data,
  });
}
// 忘记密码
/**
 * findPwd类，忘记密码接口
 * @constructor
 * @export
 * @param {*} data
 * @return {*}  {*}
 */
export function findPwd(data: any): any {
  return request({
    method: 'post',
    data,
  });
}
// 修改密码
export function changePwd(data: any): any {
  return request({
    method: 'post',
    data,
  });
}
// 帐户信息
export function allUsers(params?: any): any {
  return request({
    method: 'get',
    params,
  });
}
// 添加帐户信息
export function addUser(data: any): any {
  return request({
    method: 'post',
    data,
  });
}
// 编辑帐户信息
export function editUser(id: any, params: any): any {
  return request({
    method: 'post',
    params,
  });
}
// 删除帐户信息
export function deleteUser(params: any): any {
  return request({
    method: 'delete',
  });
}

