
import { declareListId } from '../../api/platform';
import { Options, Vue } from 'vue-class-component';
import { Provide, Watch, Emit } from 'vue-property-decorator';
interface detail {
  pageNum: number;
  pageSize: number;
  bdzdwnsrsbh: string,
  isValorem: number,
  state: number,
}
export default class TheManagement extends Vue {
  @Provide() columns: any =[
    {
      title: '展开收起',
      dataIndex: 'show',
      slots: { customRender: 'show' },
    },
    {
      title: '服务方ID',
      dataIndex: 'fwfuuid',
    },
    {
      title: '被代征人',
      dataIndex: 'bdzdwnsrmc',
      slots: { customRender: 'bdzdwnsrmc' },
    },
    {
      title: '身份证号',
      dataIndex: 'bdzdwnsrsbh',
    },
    {
      title: '服务方类型',
      dataIndex: 'fwflx',
    },
    {
      title: '月收入(元)',
      dataIndex: 'fwfddje',
      slots: { customRender: 'fwfddje' },
    },
    {
      title: '是否计税',
      dataIndex: 'sfjs',
    },
    {
      title: '应交税(元)',
      dataIndex: 'yjs',
    },
  ]
  expandedRowKeys:any = []
  @Provide() innerColumns: any =[
    {
      title: '申报税种',
      dataIndex: 'zsxm',
      slots: { customRender: 'zsxm' },
    },
    {
      title: '税率',
      dataIndex: 'slhse',
    },
    {
      title: '计税依据(元)',
      dataIndex: 'jsyj',
    },
    {
      title: '应纳税额(元)',
      dataIndex: 'ynse',
    },
    {
      title: '减免税额(元)',
      dataIndex: 'jmse',
    },
    {
      title: '应代征税额(元)',
      dataIndex: 'ydzse',
    },
  ]
  //列表数据
  data: any = [];
  pageData: any = {};
  // 当前索引
  currentIdx:number = 0
  // 表单数据
  form: {
    bdzdwnsrsbh: string;
    isValorem: number;
    state: number,
  } = {
    bdzdwnsrsbh: '',
    isValorem: 0,
    state: 0,
  }
  // 展开行
  handleExpand(record:any, idx: number):void {
    this.currentIdx = idx
		if (this.expandedRowKeys.length > 0) { 
			//判断当前点击行是否已展开，若展开则把当前key从expandedRowKeys中移除，代表关闭当前展开列
			let index = this.expandedRowKeys.indexOf(this.currentIdx);
			if (index > -1) {
				this.expandedRowKeys.splice(index, 1);
			} else {
				//关闭其他展开行，展开当前点击行
				this.expandedRowKeys = [];
				this.expandedRowKeys.push(this.currentIdx);
			}
		} else {
			//如果当前没有展开列，把当前行绑定的唯一key值放到expandedRowKeys数组中
			this.expandedRowKeys.push(this.currentIdx);
		}
	}
 /**
 * detailQuery类，代扣代缴申报查询
 * @constructor
 * @return {*}
 */
 detailQuery(): void{
   const params: detail= {
      pageNum: 1,
      pageSize: 10,
      ...this.form
   }
  this.detailData(params)
 }
 //分页
 onChange1(pageNumber: number) :void {
    let params: detail= {
      pageNum: 1,
      pageSize: 10,
      ...this.form
    }
    params.pageNum=pageNumber
    this.detailData(params)
  }
  onShowSizeChange(current: number, pageSize: number):void {
      let params: detail= {
        pageNum: 1,
        pageSize: 10,
        ...this.form
      }
      params.pageNum=current
      params.pageSize=pageSize
     this.detailData(params)
  }
 /**
 * detailData类，代扣代缴明细列表接口
 * declareList接口参数限制
 * @constructor
 * @return {*}
 */
  detailData(params?: object): void {
    const data: object = {
      ...params,
      sbsids: this.$route.query.id,
    }
    declareListId(data).then((res: any) => {
      this.data = res.data.list
      this.pageData = res.data
    })
  }
  pushOrder(record: any) {
    this.$router.push('/pushorder?query='+ record.ddids)
    // this.$router.push({
    //   path: 'pushorder',
    //   params: {
    //     // fwfuuid: record.fwfuuid,
    //     // skssqq: record.skssqq,
    //     // skssqz: record.skssqz
    //     ddids: record.ddids
    //   }
    // })
  }
  mounted(){
    this.detailData()
  }
}
