// GET /admin/ptjbxx/detail
import request from '@/untils/http';
/**
 * platformManagement类，平台管理
 * @constructor
 * @
 */
export function platformManagement(params?: any): any {
   return request({
    url: '/admin/ptjbxx/detail',
    method: 'get',
    params,
   });
 }

/**
 * theManagementList类，货主列表
 * @constructor
 * @
 */
export function theManagementList(params?: any): any {
  return request({
   url: '/admin/fbfjbxx',
   method: 'get',
   params,
  });
}


/**
 * shipperList类，货主列表
 * @constructor
 * @
 */
export function shipperList(params?: any): any {
  return request({
   url: '/admin/fbfjbxx/' + params,
   method: 'get',
  });
}


/**
 * ownerList类，车主列表
 * @constructor
 * @
 */
export function ownerList(params?: any): any {
  return request({
   url: '/admin/fwfjbxx',
   method: 'get',
   params,
  });
}


/**
 * ownerList类，车主列表
 * @constructor
 * @
 */
export function ownerListId(id: any): any {
  return request({
   url: '/admin/fwfjbxx/' + id,
   method: 'get',
  });
}

/**
 * declareList类，代扣代缴申报列表
 * @constructor
 * @
 */
export function declareList(params?: any): any {
  return request({
   url: '/admin/sbxx/sumList',
   method: 'get',
   params,
  });
}

/**
 * declareList类，代扣代缴明细列表
 * @constructor
 * @
 */
export function declareListId(params: any): any {
  return request({
   url: '/admin/sbxx/list',
   method: 'get',
   params,
  });
}

/**
 * commonController类，公共方法，
 * @constructor
 * @
 */
export function commonController(params?: any): any {
  return request({
   url: '/admin/common/likeList',
   method: 'get',
   params,
  });
}



/**
 * platformSave，修改平台信息，
 * @constructor
 * @
 */
export function platformSave(data: any): any {
  return request({
   url: '/admin/ptjbxx',
   method: 'post',
   data,
  });
}
// 批量认证 /admin/fbfjbxx/check


export function platformCheck(data: any): any {
  return request({
   url: '/admin/fbfjbxx/check',
   method: 'post',
   data,
  });
}

// 车主 /admin/fwfjbxx/check

export function fwfjbxxCheck(data: any): any {
  return request({
   url: '/admin/fwfjbxx/check',
   method: 'post',
   data,
  });
}

/**
 * Greeter类，获取公共的数据
 * @constructor
 * @
 */
export class Greeter {
  public val: string;
  public index: number;
  public pageNum?: number;
  constructor(message: string, type: number, pageNum?: number) {
    this.val = message;
    this.index = type;
    this.pageNum = pageNum;
  }
  public greet() {
     const params: any = {
       pageNum: this.pageNum || 1,
       pageSize: 100,
       name: this.val,
       type: this.index,
     };
     return request({
      url: '/admin/common/likeList',
      method: 'get',
      params,
     });
  }
}

/**
 * platformSave，修改平台信息，
 * @constructor
 * @
 */
export function fwfjbxxSave(data: any): any {
  return request({
   url: '/admin/fwfjbxx',
   method: 'put',
   data,
  });
}

/**
 * commonGetInfo，反查平台信息，
 * @constructor
 * @
 */
export function commonGetInfo(params: any): any {
  return request({
   url: '/admin/common/getInfo',
   method: 'get',
   params,
  });
}
// theManagementListFbfjbxx

export function theManagementListFbfjbxx(data: any): any {
  return request({
   url: '/admin/fbfjbxx',
   method: 'put',
   data,
  });
}

// FpxxController : 销项发票

export function fpxxList(params: any): any {
  return request({
   url: '/admin/fpxx',
   method: 'get',
   params,
  });
}
export function fpxxDelete(data: any): any {
  return request({
   url: '/admin/fpxx',
   method: 'delete',
   data,
  });
}
export function fpxxBatchPush(data: any): any {
  return request({
   url: '/admin/fpxx/batchPush',
   method: 'post',
   data,
  });
}

export function updateGps(data: any): any {
  return request({
   url: '/admin/ddxx/updateGps',
   method: 'post',
   data,
   
  });
}
export function fpxxExcel(data: any): any {
  return request({
   url: '/admin/fpxx/importExcel',
   method: 'post',
   data,
   
  });
}
export function fpxxPush(data: any): any {
  return request({
   url: '/admin/fpxx/push',
   method: 'post',
   data,
  });
}
export function fpxxViewOrder(params: any): any {
  return request({
   url: `/admin/fpxx/viewOrderList/${params.id}`,
   method: 'get'
  });
}


// LsNsrDjxxController  : 临时纳税人

export function LsNsrList(params: any): any {
  return request({
   url: '/admin/lsnsrdjxx',
   method: 'get',
   params,
  });
}
export function LsNsrDelete(data: any): any {
  return request({
   url: '/admin/lsnsrdjxx',
   method: 'delete',
   data,
  });
}
export function LsNsrPush(data: any): any {
  return request({
   url: '/admin/lsnsrdjxx/push',
   method: 'post',
   data,
  });
}
export function LsNsrBatchPush(data: any): any {
  return request({
   url: '/admin/lsnsrdjxx/batchPush',
   method: 'post',
   data,
  });
}
// class Permission {
//   // 是否允许查询，二进制第1位，0表示否，1表示是
//   public static SELECT = 1 << 0; // 0001
//   // 是否允许新增，二进制第2位，0表示否，1表示是
//   public static INSERT = 1 << 1; // 0010
//   // 是否允许修改，二进制第3位，0表示否，1表示是
//   public static UPDATE = 1 << 2; // 0100
//   // 是否允许删除，二进制第4位，0表示否，1表示是
//   public static REMOVE = 1 << 3; // 1000
//   // 存储目前的权限状态
//   public static TEST = 1 << 4;  // 10000
//   public flag: number;
//   constructor() {
//     this.flag = 0;
//   }
//   /**
//    *  重新设置权限
//    */
//   public setPermission(permission: any): void {
//     this.flag = permission;
//   }
//   /**
//    *  添加一项或多项权限
//    */
//   public enable(permission: any): void {
//     this.flag |= permission;
//   }
//   /**
//    *  删除一项或多项权限
//    */
//   public disable(permission: any): void {
//     this.flag &= ~permission;
//   }
//   /**
//    *  是否拥某些权限
//    */
//   public isAllow(permission: any): void {
//     return (this.flag & permission) === permission;
//   }
//   /**
//    *  是否禁用了某些权限
//    */
//   public isNotAllow(permission: any): void {
//     return (this.flag & permission) === 0;
//   }
//   /**
//    *  是否仅仅拥有某些权限
//    */
//   public isOnlyAllow(permission: any): void {
//     return this.flag === permission;
//   }
// }

// export new Permission()
