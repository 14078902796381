
import { Options, Vue } from "vue-class-component";
import { Provide, Watch } from 'vue-property-decorator';
@Options({
  components: {},
})
export default class Content extends Vue {
  @Provide() breadCrumbItems: any // 面包屑的数组
  $route: any
  heightDivTable:any ={
    float:"left",
    height: "100%"
  }


  @Watch('$route') handleRouteChange(to: any) {
    setTimeout(()=>{
        const heightDiv:any = document.getElementById("content")
        if( heightDiv.offsetHeight < 1000){
            this.heightDivTable.height = "100%";
        }else{
          this.heightDivTable.height = parseInt(heightDiv.offsetHeight+500)+"px"
        }
    },200)
    this.initBreadCrumbItems(to)
  }

  mounted() {
    this.initBreadCrumbItems(this.$route)
    const heightDiv:any = document.getElementById("content")
    if( heightDiv.offsetHeight < 1000){
        this.heightDivTable.height = "100%";
    }else{
      this.heightDivTable.height = parseInt(heightDiv.offsetHeight+500)+"px"
    }
  }

  initBreadCrumbItems(router: any) {
    // 根路由title
    let breadCrumbItems: any = []
    let title: string = ''
    // 遍历父级到当前子路由的页面的title和path 存储到数组里
    for (const index in router.matched) {
      if (router.matched[index].meta && router.matched[index].meta.title) {
        breadCrumbItems.push({
          path: router.matched[index].path ? router.matched[index].path : '/',
          title: router.matched[index].meta.title,
        })
      }
    }
    this.breadCrumbItems = breadCrumbItems
    this.$forceUpdate()
  }
}
