
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { UploadOutlined } from "@ant-design/icons-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { createVNode, defineComponent } from "vue";
import { Options, Vue } from "vue-class-component";
import { Provide, Watch, Emit } from "vue-property-decorator";
import {
  LsNsrList,
  LsNsrDelete,
  LsNsrBatchPush,
  LsNsrPush,
} from "../../api/platform";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import { message, Modal } from "ant-design-vue/es";
import { h } from "vue";
import request from "@/untils/http";
type Key = ColumnProps["key"];
@Options({
  components: {
    ExclamationCircleOutlined,
  },
})
export default class OwnerManagement extends Vue {
  @Provide() column: any = [
    {
      title: "服务方唯一标识",
      dataIndex: "fwfuuid",
      // slots: { customRender: 'fphm' },
    },
    {
      title: "车主姓名",
      dataIndex: "fddbrxm",
      // slots: { customRender: 'state' },
    },
    {
      title: "身份证号码",
      dataIndex: "fddbrsfzjhm",
    },
  ];
  @Provide() columns: any = [
    ...this.column,
    {
      title: "操作",
      dataIndex: "operation",
      slots: { customRender: "operation" },
    },
  ];
  @Provide() columns2: any = [
    ...this.column,
    {
      title: "推送时间",
      dataIndex: "tssj",
    },
  ];
  @Provide() columns1: any = [
    //  {
    //   title: '序号',
    //   customRender: ({text, record, index} :any)=> {
    //     return this.data.pageNum > 1 ? `${(this.data.pageNum -1)*(this.data.pageSize)+index+1}`:index+1
    //   },
    // },
    {
      title: "服务方唯一标识",
      dataIndex: "fwfuuid",
      // slots: { customRender: 'fphm' },
    },
    {
      title: "车主姓名",
      dataIndex: "fddbrxm",
      // slots: { customRender: 'state' },
    },
    {
      title: "身份证号码",
      dataIndex: "fddbrsfzjhm",
    },
    {
      title: "失败原因",
      dataIndex: "failReason",
      slots: { customRender: "failReason" },
    },
    {
      title: "操作",
      dataIndex: "operation",
      slots: { customRender: "operation" },
    },
   
  ];
  // 表单数据
  formState: {
    fddbrxm: string;
    fddbrsfzjhm: string;
    pageNum: number;
    pageSize: number;
    state: number;
  } = {
    fddbrxm: "",
    fddbrsfzjhm: "",
    pageNum: 1,
    pageSize: 10,
    state: 1,
  };
  reqData = {
    fddbrxm: "",
    fddbrsfzjhm: "",
    state: "1",
    pageNum: 1,
    pageSize: 10,
  };
  //列表数据
  data: any = [];
  total: any = null;
  Id: any = "";
  failReason: any = '';
  defaultFileList: any = [];
  visible: boolean = false;
  selectedRowKeys: any = [];
  pageSizeOptions: any = ["10", "20", "30", "40", "50"];

  //选中
  rowSelection: any = {
    onChange: (selectedRowKeys: Key[], selectedRows: []) => {
      this.selectedRowKeys = selectedRows;
      console.log(this.selectedRowKeys);
    },

    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };
  columnss(){
    if(this.reqData.state=='1'){
      return this.columns
    }else if(this.reqData.state=='2'){
      return this.columns2
    }else{
      return this.columns1
    }
  }
  handleRemove(file: any) {
    const index = this.defaultFileList.indexOf(file);
    const newFileList = this.defaultFileList.slice();
    // resFileList = resFileList.slice(-1);
    newFileList.splice(index, 1);
    this.defaultFileList = newFileList;
  }
  beforeUpload(file: any) {
    this.defaultFileList = this.defaultFileList.slice(1, 1);
    this.defaultFileList = [...this.defaultFileList, file];
    return false;
  }
  handleUpload() {
    const { defaultFileList } = this;
    const formData = new FormData();
    defaultFileList.forEach((file: any) => {
      formData.append("file", file as any);
      console.log(file);
    });

     request({
      url: "/admin/fpxx/importExcel",
      method: "post",
      data: formData,
    }).then((res) => {
      console.log(res);
       if (res.code == 200) {
        this.getList(this.reqData);
        message.success(res.message);
      }else{
        message.error(res.message);
      }
      // if(res.code ==200){
      //   message.error(res.message);
      // }
    });

  
  }
  handleChanges(info: any) {
    let resFileList = [...info.fileList];
    resFileList = resFileList.slice(-1);
    resFileList = resFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    this.defaultFileList = resFileList;
    console.log(this.defaultFileList);
  }
  getList(reqData: any): void {
    LsNsrList(reqData).then((res: any) => {
      console.log(res);
      this.data = res.data.list;
      this.total = res.data.total;
    });
  }
  filter(val: any) {
    if (val == 1) {
      return "待推送";
    } else if (val == 2) {
      return "推送完成";
    } else {
      return "推送失败";
    }
  }
  handleType(val: any): void {
    console.log(val.target.value);
    this.reqData.pageNum = 1;
    //     this.reqData={
    //   state:"1",
    //    pageNum:1,
    //   pageSize:10,
    // }
    this.getList(this.reqData);
  }
  handleOk(e: any) {
    console.log(e);
    this.visible = false;
    this.defaultFileList = [];
  }
  add(e: any) {
    if (this.defaultFileList) {
      const { defaultFileList } = this;
      const formData = new FormData();
      defaultFileList.forEach((file: any) => {
        formData.append("file", file as any);
        console.log(file);
      });

      return request({
        url: "/admin/fpxx/importExcel",
        method: "post",
        data: formData,
      }).then((res) => {    
         if (res.code == 200) {
        this.defaultFileList = [];
        message.success(res.message);
        this.getList(this.reqData);
      }else{
        message.error(res.message);
      }
        
        
      });
    }
  }
  pushList() {
    if(this.selectedRowKeys.length){
    let ids: any = [];
    console.log("推送列表", this.selectedRowKeys);
    

    
    this.selectedRowKeys.forEach((item: any) => {
      
      ids.push(item.id);
    });
    
    LsNsrBatchPush({ ids: ids }).then((res: any) => {
      console.log(res);
      if (res.code == 200) {
        message.success(res.message);
        this.getList(this.reqData);
        
      }else{
        message.error(res.message);
      }
    });
  }else{
    message.error("请选择推送的数据");
  }
  }
  examine(record: any) {
    // fpxxViewOrder({ id: record.id }).then((res: any) => {
    //   console.log(res);
      this.failReason = record;
    // });
    this.visible = true;
  }
  push(record: any) {
    let that = this;
    Modal.confirm({
      title: "是否确认推送?",
      okText: "确认",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        LsNsrPush({ id: record.id }).then((res: any) => {
          console.log(res);
          if (res.code == 200) {
        message.success(res.message);
        that.getList(that.reqData);
        
      }else{
        message.error(res.message);
      }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }
  deletes(record: any) {
    let that = this;
    Modal.confirm({
      title: "是否删除?",
      okText: "确认",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        console.log("OK");
        LsNsrDelete({ id: record.id }).then((res: any) => {
          console.log(res);
          if (res.code == 200) {
        message.success(res.message);
        that.getList(that.reqData);
        
      }else{
        message.error(res.message);
      }
        });
        // this.visible = true;
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }
  onChanges(date: any, dateString: any) {
    console.log(date, dateString);
    
  }
  onChangess(date: any, dateString: any) {
    console.log(date, dateString);

    
  }
  onValide(): void {

  }

  @Emit()
  inquire() {
    console.log(this.formState);
    this.getList(this.reqData);
    console.log("查询");
  }
  reset() {
    this.reqData = {
      fddbrxm: "",
      fddbrsfzjhm: "",
      state: "1",
      pageNum: 1,
      pageSize: 10,
    };
    this.getList(this.reqData);
    console.log("重置");
  }
  owerQuery(pageNumber?: number): void {

  }

  owerQuery1(pageNumber?: number): void {

  }
  //分页
  onChange1(pageNumber: number): void {
    this.getList(this.reqData);
 
  }
  onShowSizeChange(current: number, pageSize: number): void {
    
    this.getList(this.reqData);

  }

  shipperDetails(id: any): void {

  }

  mounted() {
    this.getList(this.reqData);

  }
}
