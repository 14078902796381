
import moment, { Moment} from 'moment'
import { Options, Vue } from 'vue-class-component';
import { Provide, Watch } from 'vue-property-decorator';
import { detail, update } from '@/api/order'
import { Form, message } from 'ant-design-vue';
import { Greeter,commonGetInfo } from '@/api/platform'
import { deepCopy } from '@/untils/until'
import {
  PlusCircleOutlined,
} from '@ant-design/icons-vue';
interface IOrderdata {
  // 1card
  ddbh: string,
  dduuid: string,
  fdfs: string,
  sfd: string,
  fdsj: string,
  sfdxxdz: string,
  mdd: string,
  mddxxdz: string,
  hwdw: string,
  hwzl: number,
  hwtj: number,
  hwmc: string,
  hwsshy: string,
  lc: string, //路程
  jdsj: string,
  jdfs: string,
  zxfs: string,
  thdfjPic: string, // 提货单
  rkdfjPic: string,  // 入库单
  hwspbm?: string,
  ddjsssqy?: string

  // 2card
  fwsj: string,
  qsfwsj: string,
  jsfwsj: string,
  jssj: string,
  gps: string,
  // 3card
  fwfddje: number,
  ptfwf: number,
  hyxje: number,
  zk: number,
  fbfddje: number,
  zffs: string,
  yhkh: string,
  skrxm: string,
  skrsfzjhm: string,
  yhls: string,
  zfpz: string,
  qtjl?: string, //邮费
  // 4card
  fbfuuid: string,
  fbfnsrmc: string,
  fbfnsrsbh: string,
  fbfxm: string, 
  fbfsfzjhm: string,
  //5card
  fwfuuid: string,
  fwfxm: string,
  fwfsfzjlx: string,
  fwfsfzjhm: string,
  cc: number,
  cx: string,
  cycph: string,
}
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  percent?: number;
  url?: string;
  preview?: string;
  originFileObj?: any;
}
interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
@Options({
  components: {
    PlusCircleOutlined
  },
})

export default class OrderDetail extends Vue {
  url:string = process.env.VUE_APP_BASEURL
  header: any ={
    Authorization: `Bearer ${localStorage.tsToken}`
  }
  previewLeftVisible: boolean = false
  previewRightVisible: boolean = false
  previewLeftImage: string | undefined = ''
  previewRightImage: string | undefined = ''
  fileLeftList: any = []
  fileRightList: any = []
  placeData: any = []
  formRef: any = {}
  visible: boolean = false
  path: any = ''
  checked: boolean = true
  @Provide() orderform: IOrderdata = {
    // 1card
    ddbh: "",
    dduuid: "",
    fdfs: "",
    sfd: "",
    fdsj: "",
    sfdxxdz: "",
    mdd: "",
    mddxxdz: "",
    hwdw: "",
    hwzl: 0,
    hwtj: 0,
    hwmc: "",
    hwsshy: "",
    lc: "", //路程
    jdsj: "",
    jdfs: "",
    zxfs: "",
    thdfjPic: "",
    rkdfjPic: '',
    hwspbm: '',
    ddjsssqy: '',

    // 2card
    fwsj: "",
    qsfwsj: '',
    jsfwsj: '',
    jssj: '',
    gps: "",
    // 3card
    fwfddje: 0,
    ptfwf: 0,
    hyxje: 0,
    zk: 0,
    fbfddje: 0,
    zffs: "",
    yhkh: "",
    skrxm: "",
    skrsfzjhm: "",
    yhls: "",
    zfpz: "",
    qtjl: "", //邮费
    // 4card
    fbfuuid: "",
    fbfnsrmc: "",
    fbfnsrsbh: "",
    fbfxm: "", 
    fbfsfzjhm: "",
    //5card
    fwfuuid: "",
    fwfxm: "",
    fwfsfzjlx: "",
    fwfsfzjhm: "",
    cc: 0,
    cx: "",
    cycph: "",
  }
  // rules: any = {
  //   // 1 card
  //   fdfs: [{required: true, message: '请填写', trigger: 'change'}],
  //   sfd: [{required: true, message: '请填写', trigger: 'blur'}],
  //   fdsj: [{required: true, message: '请填写', trigger: 'change', type: 'object'}],
  //   sfdxxdz: [{required: true, message: '请填写', trigger: 'blur'}],
  //   mdd: [{required: true, message: '请填写', trigger: 'blur'}],
  //   mddxxdz: [{required: true, message: '请填写', trigger: 'blur'}],
  //   hwdw: [{required: true, message: '请填写', trigger: 'blur'}],
  //   hwzl: [{ required: true, message: '请填写'}],
  //   hwtj: [{required: true, message: '请填写'}],
  //   hwmc: [{required: true, message: '请填写', trigger: 'blur'}],
  //   hwsshy: [{required: true, message: '请填写', trigger: 'blur'}],
  //   lc: [{required: true, message: '请填写', trigger: 'blur'}],
  //   jdsj: [{required: true, message: '请填写', trigger: 'change', type: 'object'}],
  //   jdfs: [{required: true, message: '请填写', trigger: 'blur'}],
  //   zxfs: [{required: true, message: '请填写', trigger: 'blur'}],
  //   thdfjPic: [{required: true, message: '请上传提货单'}],
  //   rkdfjPic: [{required: true, message: '请上传入库单'}],

  //   // 2 card
  //   fwsj: [{required: true, message: '请填写', trigger: 'blur'}],
  //   qsfwsj: [{required: true, message: '请填写', trigger: 'change', type: 'object'}],
  //   jsfwsj: [{required: true, message: '请填写', trigger: 'change', type: 'object'}],
  //   jssj: [{required: true, message: '请填写', trigger: 'change', type: 'object'}],

  //   // 3 card
  //   fwfddje: [{required: true, message: '请填写'}],
  //   ptfwf: [{required: true, message: '请填写'}],
  //   hyxje: [{required: true, message: '请填写'}],
  //   zk: [{required: true, message: '请填写'}],
  //   fbfddje: [{required: true, message: '请填写'}],
  //   zffs: [{required: true, message: '请填写', trigger: 'change'}],
  //   yhkh: [{required: true, message: '请填写', trigger: 'blur'},
  //     {
  //       pattern: /^[1-9]\d{9,29}$/,
  //       message: '银行卡号格式错误',
  //       trigger: 'blur'
  //     }
  //   ],
  //   skrxm: [{required: true, message: '请填写', trigger: 'blur'}],
  //   skrsfzjhm: [{required: true, message: '请填写', trigger: 'blur'},
  //    {
  //      pattern: /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0[1-9]|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/,
  //      message: '身份证格式错误',
  //      trigger: 'blur'
  //    }],
  //   yhls: [{required: true, message: '请填写', trigger: 'blur'}],
  //   zfpz: [{required: true, message: '请填写', trigger: 'blur'}],

  //   // 4 card
  //   fbfnsrmc: [{required: true, message: '请填写', trigger: 'blur'}],
  //   fbfnsrsbh: [{required: true, message: '请填写', trigger: 'blur'}],
  //   fbfxm: [{required: true, message: '请填写', trigger: 'blur'}],
  //   fbfsfzjhm: [{required: true, message: '请填写', trigger: 'blur'},
  //     {
  //       pattern: /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0[1-9]|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/,
  //       message: '身份证格式错误',
  //       trigger: 'blur'
  //     }
  //   ],

  //   // 5 card
  //   fwfxm: [{required: true, message: '请填写', trigger: 'blur'}],
  //   fwfsfzjlx: [{required: true, message: '请填写', trigger: 'blur'}],
  //   fwfsfzjhm: [
  //     {required: true, message: '请填写', trigger: 'blur'},
  //     {
  //       pattern: /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0[1-9]|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/,
  //       message: '身份证格式错误',
  //       trigger: 'blur'
  //     }
  //   ],
  //   cc: [{required: true, message: '请填写', trigger: 'blur'}],
  //   cx: [{required: true, message: '请填写', trigger: 'blur'}],
  //   cycph: [{required: true, message: '请填写', trigger: 'blur'}],
  // }
  created() {
    /**
     * @description: 订单详情接口
     * @param {*}
     * @return {*}
     */

     const data:any = {
      appId:this.$router.currentRoute.value.query.app_id,
      signKey:this.$router.currentRoute.value.query.sign_key,
      time:this.$router.currentRoute.value.query.time,
      id:this.$router.currentRoute.value.query.dduuid,
      infoType:1
    }
    commonGetInfo(data).then((res: any)=> {
      if (res.code == 200) {
        this.orderform = { ...res.data}
        // 缩略图
        this.fileLeftList = [{
          uid: '-1',
          name: 'left.png',
          status: 'done',
          url: res.data.thdfjThn
        }]
        this.fileRightList = [{
          uid: '-2',
          name: 'right.png',
          status: 'done',
          url: res.data.rkdfjThn,
        }]
        // 原图
        this.previewLeftImage = res.data.thdfjPic
        this.previewRightImage = res.data.rkdfjPic
      }
    })
  }
  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  mounted() {
    /** 公用方法 获得地点（市、县）
     * @description: 
     * @param {*}
     * @return {*}
     */    
    const gree = new Greeter('', 2)
    gree.greet().then((res: any)=> {
      if (res.code == 200) {
        this.placeData = res.data.list
      }
    })
    this.path = this.$route.query.path as string
  }
  /**
   * @description: 开启图片预览
   * @param {*} file
   * @return {*}
   */  
  async handlePreview(file: FileItem) {
    if (!file.url && !file.preview) {
      file.preview = (await this.getBase64(file.originFileObj)) as string;
    }
    this.previewLeftImage = this.previewLeftImage ? this.previewLeftImage: file.url || file.preview;
    this.previewLeftVisible = true;
    this.previewRightImage = this.previewRightImage ? this.previewRightImage: file.url || file.preview;
    this.previewRightVisible = true;
  }
  /**
   * @description: 关闭图片预览
   * @param {*}
   * @return {*}
   */  
  handleCancel():void {
    this.previewLeftVisible = false
    this.previewRightVisible = false
  }
  RemoveImg(file: File): void {
  
  }
  /**
   * @description: 提货单图片路径赋值
   * @param {*}
   * @return {*}
   */  
  handleLeftChange({ fileList: newFileList }: FileInfo):void {
    this.fileLeftList = newFileList;
    if (this.fileLeftList[0]&&this.fileLeftList[0].status == 'done') {
      this.orderform.thdfjPic = this.fileLeftList[0].response.data.url
    } else {
       this.orderform.thdfjPic = ''
    }
    this.formRef = this.$refs.formRef
    this.formRef.validateFields('thdfjPic');
  }
    /**
   * @description: 入库单图片路径赋值
   * @param {*}
   * @return {*}
   */
  handleRightChange({ fileList: newFileList }: FileInfo):void {
    this.fileRightList = newFileList;
    if (this.fileRightList[0] &&this.fileRightList[0].status == 'done') {
      this.orderform.rkdfjPic = ''
    } else {
      this.orderform.rkdfjPic = this.fileRightList[0].response.data.url
    }
    this.formRef = this.$refs.formRef
    this.formRef.validateFields('rkdfjPic');
  }
  /**
   * @description: 取消
   * @param {*}
   * @return {*}
   */  
  onCancel():void {
    if (this.path === 'error') {
      this.$router.push('/errororder')
    } else {
      this.$router.push('/pushorder')
    }
  }
  /**
   * @description: 提交
   * @param {*}
   * @return {*}
   */  
  onSubmit() :void {
    this.formRef = this.$refs.formRef
    this.formRef.validate().then(() => {
      const newForm = deepCopy(this.orderform)
      newForm.fdsj = moment(this.orderform.fdsj).format('YYYY-MM-DD HH:mm:ss')
      newForm.jdsj = moment(this.orderform.jdsj).format('YYYY-MM-DD HH:mm:ss')
      newForm.qsfwsj = moment(this.orderform.qsfwsj).format('YYYY-MM-DD HH:mm:ss')
      newForm.jsfwsj = moment(this.orderform.jsfwsj).format('YYYY-MM-DD HH:mm:ss')
      newForm.jssj = moment(this.orderform.jssj).format('YYYY-MM-DD HH:mm:ss')
      update(newForm).then((res: any)=> {
      if (res.code == 200) {
        message.success('数据保存成功', 5)
        this.onCancel()
      }
    })
    }).catch((error: any) => {
    })
  }
}
